import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import Footer from "../views/simulator/footer";
import { Tooltip } from "reactstrap";

import { width } from "../helpers/general";

function SimulatorLayout({ children, ...props }) {
  const cardStyle = {
    margin: "15px auto",
    backgroundColor: props.leilaoInss ? "#ececec" : "white",
    boxShadow: props.leilaoInss ? "0px 0px 25px rgba(0, 0, 0, 0.4)" : "none",
  };

  return (
    <>
      {!props.admin && <div className="simulatorbg"></div>}
      {/* {width() !== 'mobile' && < div className="simulatorbg" ></div>} */}
      <div
        className="container-md"
        style={{
          display: "flex",
          paddingTop: !props.admin ? "3%" : "0%",
          margin: "auto",
          flexDirection: "column",
        }}
      >
        <div className="col-md-8 col-12 card" style={cardStyle}>
          <form
            className="row"
            // style={{ backgroundColor: _bgColor }}
            onSubmit={(e) => e.preventDefault()}
          >
            {children}
          </form>

          <Footer hideContact={props.hideContact} leilaoInss={props.leilaoInss} preencherDados={props.preencherDados} />
        </div>
      </div>
    </>
  );
}

export const Buttons = ({ ...props }) => {
  const [sending, setSending] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setSending(false);
  }, []);

  useEffect(() => {
    setDisabled(props.disabledSecond)
  }, [props.disabledSecond])


  function handleSubmit() {
    setSending(true);
    if (!props.submit(setSending)) setSending(false);
  }

  return (
    <div
      className="row buttons"
      style={{
        justifyContent: "space-between",
        padding: "20px 20px",
      }}
    >
      {width() === "mobile" ? (
        <>
          <button
            style={{
              backgroundColor: "#3E6C84",
              alignSelf: props.retBack ? "right" : "",
            }}
            onClick={props.submit}
            disabled={disabled}
            className="btn btn-lg btn-info col-md-4 mb-1 font-weight-bold"
          >
            {props.second}
          </button>

          {!props.retBack ? (
            <button
              style={{ color: "#e2e3e5", backgroundColor: "#3E6C84" }}
              onClick={props.goBack}
              disabled={props.disabledFirst}
              className="btn btn-lg btn-info col-md-4 mb-1 font-weight-bold "
            >
              {props.first}
            </button>
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          {!props.retBack ? (
            <button
              style={{ color: "#e2e3e5", backgroundColor: "#3E6C84" }}
              onClick={props.goBack}
              disabled={props.disabledFirst}
              className="btn btn-lg btn-info col-md-4 mb-1 font-weight-bold "
            >
              {props.first}
            </button>
          ) : (
            ""
          )}

          <button
            style={{
              backgroundColor: "#3E6C84",
              alignSelf: props.retBack ? "right" : "",
            }}
            onClick={handleSubmit}
            // disabled={sending || props.disabledSecond}
            disabled={props.disabledSecond}
            className="btn btn-lg btn-info col-md-4 mb-1 font-weight-bold"
          >
            {props.second}
          </button>
        </>
      )}
    </div>
  );
};

Buttons.propTypes = {
  first: propTypes.string,
  second: propTypes.string,
  submit: propTypes.func.isRequired,
  goBack: propTypes.func.isRequired,
};

Buttons.defaultProps = {
  first: "Voltar",
  second: "Avançar",
  submit: () => false,
};

export const InputLabelAlert = ({ ...props }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [help, setHelp] = useState(false);
  const inputRef = useRef();

  const toggle = () => {
    if (help) {
      setTooltipOpen(false);
    } else setTooltipOpen(!tooltipOpen);
  };

  function handleHelp() {
    props.help(setHelp);
  }

  if (props.setRef) props.setRef(inputRef)

  return (
    <>
      {props.label && props.type != "checkbox" ? (
        <div style={{ display: "flex" }}>
          <label
            htmlFor={props.id}
            className={
              props.className
                ? props.className
                : props.marginRight
                  ? "label"
                  : "no-margin"
            }
            style={{ marginRight: props.marginRight ? props.marginRight : "" }}
          >
            {props.label} {props.cifrao && "(R$)"}{" "}
            {props.help ? (
              <span className="custom-info-flex" onClick={handleHelp}>
                ?
              </span>
            ) : (
              ""
            )}
          </label>
        </div>
      ) : (
        ""
      )}

      <input
        className={`form-control ${props.inputClass} ${!props.lowercase && "text-uppercase"
          }`}
        type={props.type}
        name={props.id}
        id={props.id}
        step={props.step}
        value={props.value}
        onChange={props.handle || props.onChange}
        maxLength={props.maxLength || props.length || 250}
        onFocus={props.onFocus}
        // minLength={props.minLength}
        disabled={props.disabled}
        onBlur={props.onBlur}
        onClick={props.click}
        style={{
          borderColor: props.warning ? "red" : "",
          textAlign: props.type == "numeric" ? "right" : "left",
        }}
        list={props.list}
        autoFocus={props.focus}
        onContextMenu={props.onContextMenu}
        ref={props.ref || inputRef}
        autoComplete="off"
        onPaste={props.onPaste}
        required={props.required}
        title={props.title}
        max={props.maxValue}
      />
      {props.type == "checkbox" && <label for={props.id}>{props.label}</label>}

      {props.tipText ? (
        <Tooltip
          placement="bottom"
          isOpen={tooltipOpen}
          target={props.id}
          toggle={toggle}
        >
          {props.tipText}
        </Tooltip>
      ) : (
        ""
      )}

      <InputAlert
        display={props.warning}
        field={props.label}
        messages={props.field || props.messages || props.msgErro}
      />
    </>
  );
};

InputLabelAlert.propTypes = {
  id: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  value: propTypes.string.isRequired,
  handle: propTypes.func.isRequired,
  warning: propTypes.number.isRequired,
  list: propTypes.string,
  length: propTypes.string,
  // minLength: propTypes.number,
  onContextMenu: propTypes.func,
  onPaste: propTypes.func,
  inputClass: propTypes.string,
};

InputLabelAlert.defaultProps = {
  value: "",
  label: "",
  inputClass: "",
  warning: 0,
  minLength: 0,
  list: "",
  length: "",
  onContextMenu: () => true,
  onPaste: () => true,
};

export const InputAlert = ({ ...props }) => (
  <p
    className="alert alert-danger"
    style={{ display: props.display ? "block" : "none", textAlign: "left" }}
    title={"Favor preencher o campo " + props.field + " sem acentos ou caractres especiais ex: ç%@#$/|..."}
  >
    {/* {props.messages || `Favor preencher o campo: ${props.field}`} */}
    {props.messages || `Campo obrigatório ou inválido: ${props.field}`}
  </p>
);

InputAlert.propTypes = {
  display: propTypes.number,
  field: propTypes.string,
  messages: propTypes.string,
};

InputAlert.defaultProps = {
  display: 0,
};

export const Pagination = ({ ...props }) => {
  const [qtdLinesPage, setQtdLinesPage] = useState(props.qtdLinesPage || 10)
  let paginas = width() == "mobile" ? 4 : 10;
  const [exibindo, setExibindo] = useState(0);
  const [inicio, setInicio] = useState(0);
  const [atual, setAtual] = useState();
  // const [max, setMax] = useState()

  let max;
  if (width() == "mobile")
    max =
      props.detailed?.length > 7 ? Math.ceil(props.detailed?.length / 7) : 1;
  else
    max =
      props.detailed?.length > (qtdLinesPage) ? Math.ceil(props.detailed?.length / (qtdLinesPage)) : 1;

  //-- Diogo 31.07.2023 - Atribuindo propriedade lastPage para o max    
  max = props.lastPage || max

  useEffect(() => {
    console.log("Pagination.useEffect(qtdLinesPage): ", { qtdLinesPage, max, props })
    // _setMax()
  }, [props.qtdLinesPage])

  // function _setMax() {
  //   if (width() == "mobile")
  //     setMax(props.detailed?.length > 7 ? Math.ceil(props.detailed?.length / 7) : 1);
  //   else
  //     setMax(props.detailed?.length > (qtdLinesPage) ? Math.ceil(props.detailed?.length / (qtdLinesPage)) : 1);
  // }

  useEffect(() => {
    const atual = props.actual + 1
    // console.log("Pagination - useEffect: ", props.actual);
    // console.log("Pagination - exibindo: ", exibindo);
    setAtual(atual);

    if (props.actual >= exibindo) {
      setInicio(inicio + 1);
      setExibindo(exibindo + 1);
    }

    //-- Diogo 01.08.2023 - Correção para exibir páginas anteriores
    if (atual < inicio) {
      setInicio(atual)
      setExibindo(atual + paginas - 1)
    }
    // else if (props.actual <= exibindo - paginas && props.actual > 0) {
    //   setInicio(inicio - 1);
    //   setExibindo(exibindo - 1);
    // }
    // _setMax()
  }, [props.actual]);

  const Paginas = () => {
    let index = [];
    if (exibindo <= 1 && max > 1) setExibindo(paginas);
    if (exibindo > max && max > 1) setExibindo(max);
    if (exibindo < paginas && max > paginas) setExibindo(paginas)
    if (max < paginas) setExibindo(max);

    // console.log("Pagination - Paginas: ", { inicio, exibindo, max, atual, paginas })

    let i = (exibindo - inicio >= paginas ? inicio : exibindo - paginas) || 0;
    i = i < 0 ? 0 : i;
    for (; i < exibindo; i++) {
      index.push(
        <li
          style={{ cursor: "pointer" }}
          value={i}
          onClick={props.changePage}
          key={i}
          className="page-item"
        >
          <span
            className={`page-link ${props.actual === i || (!props.actual && i == 0) ? "text-danger font-weight-bold" : ""
              }`}
          >
            {i < 9 ? "0" : ""}
            {i + 1}
          </span>
        </li>
      );
    }

    return index;
  };

  const handlePage = (e, proximo) => {
    if (!proximo || props.actual < max - 1)
      if (props && props.changePage) props.changePage(e);
  };

  return (
    <div>
      <nav aria-label="Page navigation" style={{ width: "100%" }}>
        <ul className="pagination" id={`pagination-max-${max}`} >
          <li
            style={{ cursor: "pointer" }}
            value={props.actual - 1}
            onClick={(e) => (props.actual ? handlePage(e, false) : null)}
            className={`page-item ${!props.actual ? "disabled" : ""}`}
          >
            <span className="page-link">
              {width() == "mobile" ? "<" : "Anterior"}
            </span>
          </li>
          <Paginas />
          <li
            style={{ cursor: "pointer" }}
            value={props.actual + 1}
            onClick={(e) =>
              props.actual < max - 1 ? handlePage(e, true) : null
            }
            className={`page-item ${props.actual === max - 1 ? "disabled" : ""
              }`}
          >
            <span className="page-link">
              {width() == "mobile" ? ">" : "Próximo"}
            </span>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export const FilterSelect = ({ ...props }) => {
  let options = props.detailed && props.detailed[props.oLabel];
  if (!props.naoOrdenar && options?.length > 0) options.sort();
  // console.log("FilterSelect - options: ", options)
  return (
    <select
      onChange={props.handleOption}
      data-label={props.oLabel}
      className="form-control"
      title={props.title || props.tip || "Selecione um valor para filtrar"}
      style={{ lineHeight: "2.2em", fontSize: "0.8em" }}
    >
      {!options && props.showCarregando && <option value="carregando">Carregando...</option>}
      {!props.ocultarTodos && (options || !props.showCarregando) && <option value="todos">Todos</option>}
      {options &&
        options.map(
          (option, i) =>
            option && <FilterOption key={option} value={option} selected={props.value == option} />
        )}
    </select>
  );
};

export const FilterOption = ({ ...props }) => {
  // if (props.selected)
  //   console.log("FilterOption - props: ", { props, selected: props.selected })
  return (
    < option key={props.key} value={props.value} selected={props.selected} >
      {props.value}
    </option >)
};

export default SimulatorLayout;
