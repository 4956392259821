import React, { useState } from "react";
import { Button } from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import Swal from "./../../../components/swal";
import { sendMultiPartData, sendData, getData } from "../../../helpers/auth";
import { uriList } from "../../../helpers/config";

import { Pagination, FilterSelect, InputLabelAlert } from "../../layout";

let statuses = [
  { value: "ATIVO", nome: "Ativo" },
  { value: "INATIVO", nome: "Inativo" },
];

const opcoesSimNao = [
  { id: "sim", nome: "Sim", value: "sim" },
  { id: "nao", nome: "Não", value: "nao" },
];

let _competencia = [
  { value: -1, descricao: "Não" },
  { value: 1, descricao: "Sim" },
];

const swalOptions = {
  title: "Deseja realmente excluir o registro selecionado?",
  icon: "warning",
  buttons: {
    cancel: {
      text: "Não",
      value: null,
      visible: true,
      className: "",
      closeModal: true,
    },
    confirm: {
      text: "Sim",
      value: true,
      visible: true,
      className: "bg-danger",
      closeModal: true,
    },
  },
};

const tiposAutorizacao = [
  { id: "NAO", nome: "Não", value: "NAO" },
  { id: "INSS_IN100", nome: "INSS_IN100", value: "INSS_IN100" },
  { id: "SICOOB", nome: "SICOOB", value: "SICOOB" },
];

const tiposAssinatura = [
  { id: "MANUAL", nome: "Manual", value: "MANUAL" },
  { id: "AUTOMATICO", nome: "Automático", value: "AUTOMATICO" },
];

const tiposPagamento = [
  { id: "MANUAL", nome: "Manual", value: "MANUAL" },
  { id: "AUTOMATICO", nome: "Automático", value: "AUTOMATICO" },
];

const tiposTela = [
  { id: "NAO", nome: "Não", value: "NAO" },
  { id: "SICOOB", nome: "Sicoob", value: "SICOOB" },
  { id: "ORIGINAL", nome: "Original", value: "ORIGINAL" },
];

const tiposConvenio = [
  { id: "OUTROS", nome: "Outros", value: "OUTROS" },
  { id: "SICOOB", nome: "Sicoob", value: "SICOOB" },
  { id: "ORIGINAL", nome: "Original", value: "ORIGINAL" },
  { id: "INSS", nome: "INSS", value: "INSS" },
  { id: "CARTAO", nome: "CARTAO", value: "CARTAO" },
  { id: "FGTS", nome: "FGTS", value: "FGTS" },
];

const tiposCredito = [
  { id: "CONSIGNADO", nome: "Consignado", value: "CONSIGNADO" },
  { id: "PESSOAL", nome: "Pessoal", value: "PESSOAL" },
  { id: "EMPRESARIAL", nome: "Empresarial", value: "EMPRESARIAL" },
  { id: "OUTROS", nome: "Outros", value: "OUTROS" },
];

const Form = ({ ...props }) => {
  let {
    filterOptions,
    handleOption,
    convenios,
    actualPage,
    handlePage,
    callback,
    sortArrObj,
    setClickedTr,
    clickedTr,
    setConvenios,
    integracoes,
  } = props;

  const [show, setShow] = useState(false);
  const [id, setId] = useState(0);
  const [nome, setNome] = useState("");
  const [codigo, setCodigo] = useState("");
  const [linha, setLinha] = useState("");
  const [orgao, setOrgao] = useState("");
  const [email_aguardDef, setEmailAguardDef] = useState("");
  const [codigoOrgao, setcodigoOrgao] = useState("");
  const [tabelapadrao_id, setTabelaPadrao_id] = useState(null);
  const [integracao_id, setIntegracao_id] = useState(props.integracao_id);
  const [tabelas, setTabelas] = useState([]);
  const [status, setStatus] = useState("ATIVO");
  const [exibir_contratos_port, setExibir_contratos_port] = useState("nao");
  const [prazo, setPrazo] = useState("");
  const [tipo_autorizacao, setTipoAutorizacao] = useState("NAO");
  const [tipo, setTipo] = useState("");
  const [tipoCredito, setTipoCredito] = useState("CONSIGNADO");
  const [envio_assinatura, setEnvioAssinatura] = useState("AUTOMATICO");
  const [pagamento, setPagamento] = useState("AUTOMATICO");
  const [tela_complementar, setTelaComplementar] = useState("NAO");
  const [competencia, setCompetencia] = useState(-1)
  const [sigla, setSigla] = useState("")

  const [method, setMethod] = useState("POST");

  const handleClose = () => setShow(false);

  const handleEdit = async (id) => {
    getData({ uri: uriList("convenios") + "/" + id })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error();

        setNome(res.nome);
        setCodigo(res.codigo);
        setLinha(res.linha);
        setOrgao(res.orgao);
        setcodigoOrgao(res.codigo_orgao);
        setId(id);
        setTabelaPadrao_id(res.tabelapadrao_id);
        setIntegracao_id(res.integracao_id);
        setMethod("PUT");
        setTabelas(res.tabelas);
        setStatus(res.status);
        setExibir_contratos_port(res.exibir_contratos_port);
        setTipoAutorizacao(res.tipo_autorizacao);
        setTipo(res.tipo);
        setEnvioAssinatura(res.envio_assinatura);
        setPagamento(res.pagamento);
        setTelaComplementar(res.tela_complementar);
        setEmailAguardDef(res.email_aguarddef);
        setTipoCredito(res.tipo_credito);
        setCompetencia(res.competencia)
        setSigla(res.sigla)

        setShow(true);

        if (res.integracoes && !res.integracao_id)
          setIntegracao_id(res.integracoes[0].id);
      })
      .catch((e) => {
        return false;
      });
  };

  const handleDelete = async (isConfirm, swal, id) => {
    if (isConfirm) {
      await sendData({
        uri: uriList("convenios") + "/" + id,
        content: {},
        method: "DELETE",
      })
        .then((res) => {
          if (res.status && parseInt(res.status) > 200) throw new Error();
          callback();
        })
        .catch((e) => {
          alert("Erro ao excluir convênio.");
        });
    }
  };

  const handlePost = async () => {
    let dObj = {};

    dObj["nome"] = nome;
    dObj["codigo"] = codigo;
    dObj["linha"] = linha;
    dObj["orgao"] = orgao;
    dObj["tabelapadrao_id"] = tabelapadrao_id;
    dObj["integracao_id"] = integracao_id || integracoes[0]?.id;
    dObj["codigo_orgao"] = codigoOrgao;
    dObj["status"] = status;
    dObj["prazo"] = prazo;
    dObj["exibir_contratos_port"] = exibir_contratos_port;
    dObj["tipo_autorizacao"] = tipo_autorizacao;
    dObj["tipo"] = tipo;
    dObj["tipo_credito"] = tipoCredito;
    dObj["envio_assinatura"] = envio_assinatura;
    dObj["pagamento"] = pagamento;
    dObj["tela_complementar"] = tela_complementar;
    dObj["email_aguarddef"] = email_aguardDef;
    dObj["competencia"] = competencia;
    dObj["sigla"] = sigla;

    console.log("Gravando convênio...");
    console.log(dObj);
    if (method === "PUT") dObj["id"] = id;

    await sendData({
      uri: uriList("convenios") + (method === "PUT" ? "/" + id : ""),
      content: dObj,
      method,
    })
      .then((res) => {
        if (res.status && parseInt(res.status) > 200)
          throw new Error(res.message[0].message);
        callback();
        setShow(false);
      })
      .catch((e) => {
        alert(e);
      });
  };

  const handleNew = () => {
    setNome("");
    setCodigo("");
    setLinha("");
    setOrgao("");
    setPrazo("");
    setExibir_contratos_port("nao");
    setcodigoOrgao("");
    setTabelaPadrao_id(0);
    setIntegracao_id(0);
    setMethod("POST");
    setStatus("ATIVO");
    setEmailAguardDef("");
    setId(0);
    setTipoAutorizacao("NAO");
    setTipo("OUTROS");
    setEnvioAssinatura("AUTOMATICO");
    setPagamento("AUTOMATICO");
    setTelaComplementar("NAO");
    setTipoCredito("CONSIGNADO");
    setCompetencia(-1)
    setSigla("")

    setShow(true);
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Convênios</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="maxWidth750">
          <div>
            <div className="row">
              <div className="col-md-3">
                <label className="m-0 font-90">Status</label>
                <select
                  className="form-control"
                  defaultValue={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  {statuses.map((item, key) => {
                    return (
                      <option key={key} value={item.value}>
                        {item.nome}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-md-6">
                <label className="m-0 font-90">Nome</label>
                <input
                  type="text"
                  className="form-control"
                  autoFocus
                  value={nome}
                  onChange={(e) => setNome(e.target.value.toUpperCase())}
                />
              </div>

              <div className="col-md-3">
                <label className="m-0 font-90">Código</label>
                <input
                  type="text"
                  className="form-control"
                  value={codigo}
                  onChange={(e) => setCodigo(e.target.value.toUpperCase())}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="m-0 font-90">Linha</label>
                <input
                  type="text"
                  className="form-control"
                  value={linha}
                  onChange={(e) => setLinha(e.target.value.toUpperCase())}
                />
              </div>

              <div className="col-md-4">
                <label className="m-0 font-90">Orgão</label>
                <input
                  type="text"
                  className="form-control"
                  value={orgao}
                  onChange={(e) => setOrgao(e.target.value.toUpperCase())}
                />
              </div>

              <div className="col-md-4">
                <label className="m-0 font-90">Código do Orgão</label>
                <input
                  type="text"
                  className="form-control"
                  value={codigoOrgao}
                  onChange={(e) => setcodigoOrgao(e.target.value.toUpperCase())}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                <label className="m-0 font-90">Prazo(Ex: 12;24;36)</label>
                <input
                  type="text"
                  className="form-control"
                  value={prazo}
                  onChange={(e) => setPrazo(e.target.value.toUpperCase())}
                />
              </div>

              <div className="col-md-4">
                <label className="m-0 font-90">Tipo de Crédito</label>
                <select
                  className="form-control"
                  defaultValue={tipoCredito}
                  onChange={(e) => setTipoCredito(e.target.value)}
                >
                  {tiposCredito &&
                    tiposCredito.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.nome}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <label className="m-0 font-90">
                  Exibir Cont. Portabilidade
                </label>
                <select
                  className="form-control"
                  defaultValue={exibir_contratos_port}
                  onChange={(e) => setExibir_contratos_port(e.target.value)}
                >
                  {opcoesSimNao &&
                    opcoesSimNao.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.nome}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="col-md-3">
                <label className="m-0 font-90">Tabela Padrão</label>
                <select
                  className="form-control"
                  defaultValue={tabelapadrao_id}
                  onChange={(e) => setTabelaPadrao_id(e.target.value)}
                >
                  {tabelas &&
                    tabelas.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.nome}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="col-md-3">
                <label className="m-0 font-90">Tipo de Integracao</label>
                <select
                  className="form-control"
                  defaultValue={integracao_id}
                  onChange={(e) => setIntegracao_id(e.target.value)}
                >
                  {integracoes &&
                    integracoes.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.nome}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="col-md-3">
                <label className="m-0 font-90">Tipo de Autorizacao</label>
                <select
                  className="form-control"
                  defaultValue={tipo_autorizacao}
                  onChange={(e) => setTipoAutorizacao(e.target.value)}
                >
                  {tiposAutorizacao &&
                    tiposAutorizacao.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.nome}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <label className="m-0 font-90">Envio para Assinatura</label>
                <select
                  className="form-control"
                  defaultValue={envio_assinatura}
                  onChange={(e) => setEnvioAssinatura(e.target.value)}
                >
                  {tiposAssinatura &&
                    tiposAssinatura.map((item, key) => (
                      <option key={key} value={item.id}>
                        {item.nome}
                      </option>
                    ))}
                </select>
              </div>

              <div className="col-md-3">
                <label className="m-0 font-90">Forma de Pagamento</label>
                <select
                  className="form-control"
                  defaultValue={pagamento}
                  onChange={(e) => setPagamento(e.target.value)}
                >
                  {tiposPagamento &&
                    tiposPagamento.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.nome}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="col-md-3">
                <label className="m-0 font-90">Tela Complementar</label>
                <select
                  className="form-control"
                  defaultValue={tela_complementar}
                  onChange={(e) => setTelaComplementar(e.target.value)}
                >
                  {tiposTela &&
                    tiposTela.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.nome}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="col-md-3">
                <label className="m-0 font-90">Tipo de Convênio</label>
                <select
                  className="form-control"
                  defaultValue={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                >
                  {tiposConvenio &&
                    tiposConvenio.map((item, key) => {
                      return (
                        <option key={key} value={item.id}>
                          {item.nome}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-3">
                <label className="m-0 font-90">Virada Folha(Competência)</label>
                <select
                  className="form-control"
                  defaultValue={competencia}
                  title="Virada da folha de pagamento?"
                  onChange={(e) => setCompetencia(e.target.value)}
                >
                  {_competencia.map((item, key) => {
                    return (
                      <option key={key} value={item.value}>
                        {item.descricao}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3">
                <label className="m-0 font-90">
                  Código/Nome Banco
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={sigla}
                  onChange={(e) => setSigla(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label className="m-0 font-90">
                  Email Aguardando Deferimento
                </label>
                <input
                  type="email"
                  className="form-control"
                  value={email_aguardDef}
                  onChange={(e) =>
                    setEmailAguardDef(e.target.value.toLowerCase())
                  }
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color="success" onClick={handlePost}>
            Gravar
          </Button>
          <Button color="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="col-md-4 py-1 px-0">
        <button
          onClick={() => handleNew()}
          className="btn btn-sm text-light"
          title="Novo convênio"
          style={{ backgroundColor: "#00bdff", marginRight: "3px" }}
        >
          <i className="mdi mdi-plus-box" />
        </button>
        <button
          onClick={() => window.print()}
          className="btn btn-sm text-light"
          title="Imprimir"
          style={{ backgroundColor: "#00bdff" }}
        >
          <i className="mdi mdi-printer" />
        </button>
      </div>

      <div className="row ">
        <div className={`col-md-2`}>
          <label className="m-0 font-90">Convênio</label>
          <FilterSelect
            detailed={filterOptions}
            handleOption={handleOption}
            oLabel="nome"
          />
        </div>

        <div className={`col-md-2`}>
          <label className="m-0 font-90">Código</label>
          <FilterSelect
            detailed={filterOptions}
            handleOption={handleOption}
            oLabel="codigo"
          />
        </div>

        <div className={`col-md-2`}>
          <label className="m-0 font-90">Linha</label>
          <FilterSelect
            detailed={filterOptions}
            handleOption={handleOption}
            oLabel="linha"
          />
        </div>

        <div className={`col-md-2`}>
          <label className="m-0 font-90">Orgão</label>
          <FilterSelect
            detailed={filterOptions}
            handleOption={handleOption}
            oLabel="orgao"
          />
        </div>

        <div className={`col-md-2`}>
          <label className="m-0 font-90">Tabela Padrão</label>
          <FilterSelect
            detailed={filterOptions}
            handleOption={handleOption}
            oLabel="tabelapadrao_id"
          />
        </div>
      </div>
      <div className="row py-2">
        <div className={`col-md-12`}>
          <InputLabelAlert
            className="m-0 font-90"
            type="text"
            label="Convênio/Código/Linha"
            id="convenio_filter"
            cifrao={false}
            value={props.convenio_filter}
            handle={props.handleConvenio}
            focus={true}
          />
        </div>
      </div>

      <table className="table table-bordered table-striped">
        <thead>
          <tr
            title="Clique para ordenar"
            style={{ backgroundColor: "#f2f2f2" }}
          >
            <th scope="col" className="font-80">
              #
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("nome", "string")}
            >
              Convênio
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("codigo", "numeric")}
            >
              Código
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("linha", "string")}
            >
              Linha
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("orgao", "string")}
            >
              Orgão
            </th>
            <th
              scope="col"
              className="font-80"
              onClick={() => sortArrObj("tabelapadrao_id", "string")}
            >
              Tabela Padrão
            </th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {convenios.length > 0 ? (
            convenios.map(
              (convenio, i) =>
                i >= actualPage * 10 &&
                i < (actualPage + 1) * 10 && (
                  <tr
                    key={i}
                    className={`${clickedTr === convenio.id ? "bg-secondary text-light" : ""
                      }`}
                    onClick={() => {
                      setClickedTr(convenio.id);
                    }}
                    onDoubleClick={() => {
                      handleEdit(convenio.id);
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setClickedTr(convenio.id);
                    }}
                  >
                    <td>{i + 1}</td>
                    <td>{convenio.nome}</td>
                    <td>{convenio.codigo}</td>
                    <td>{convenio.linha}</td>
                    <td>{convenio.orgao}</td>
                    <td>{convenio.tabelapadrao}</td>
                    <td className="text-center">
                      <button
                        className="btn btn-sm btn-warning"
                        title="Altera um registro"
                        onClick={(e) => handleEdit(convenio.id)}
                      >
                        <i className="mdi mdi-file-edit-outline"></i>
                      </button>
                      <Swal
                        options={swalOptions}
                        id={convenio.id}
                        title="Exclusão de Registro"
                        callback={handleDelete}
                        className="btn btn-sm btn-danger"
                        style={{ marginLeft: "2px", cursor: "pointer" }}
                      >
                        <i className="mdi mdi-delete"></i>
                      </Swal>
                    </td>
                  </tr>
                )
            )
          ) : (
            <tr>
              <td>Sem dados</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2" className="text-left">
              Total de Convênios: {convenios && convenios.length}
            </td>

            <td colSpan="7"></td>
          </tr>
        </tfoot>
      </table>
      <Pagination
        detailed={convenios}
        actual={actualPage}
        changePage={handlePage}
      />
    </>
  );
};

export default Form;
