import React, { useState, useEffect } from "react";
// import React from 'react';

import { formatCurrency } from "../helpers/general";
import "./../css/style.css";

const Table = ({ ...props }) => {
  let matrix = [Object.keys(props.matrix), Object.values(props.matrix)];
  let fields = ["valor_liquido", "valor_parcelas", "prazo"];
  let cFields = props.admin
    ? [
      "Valor_Liquido",
      "Prazo",
      "Valor_Parcelas",
      "IOF",
      "Seguro",
      "Operacao",
      "Convenio",
      "Taxa_Juros_Mensal",
      "Taxa_Juros_Anual",
      "Custo_Efetivom",
      "Custo_Efetivo",
    ]
    : [
      "Valor_Liquido",
      "Prazo",
      "Valor_Parcelas",
      "IOF",
      "Seguro",
      "Taxa_Juros_Mensal",
      "Taxa_Juros_Anual", //"Total_Geral",
      "Custo_Efetivom",
      "Custo_Efetivo",
    ];

  if (props?.operacao?.toLowerCase() !== "contrato novo")
    cFields.push("Valor_Total");

  // if (props?.operacao?.toLowerCase() !== "portabilidade")
  //   cFields.unshift("Valor_Liquido");

  if (props && props.simularPort) cFields.push("Saldo_Devedor");

  let dFields = cFields.map((custo) => custo.toLowerCase());

  let { bordered, detailed } = props;

  console.log("Table:", props.matrix);
  const getLegenda = (row) => {
    let legenda = row.replace(/\_/g, " ");
    switch (row.toLowerCase()) {
      case "valor_liquido":
        if (!props.operacao || props.operacao.toLowerCase() == "contrato novo")
          legenda = "Valor do Crédito(R$)";
        else {
          if (props.operacao.toLowerCase() == "cartao")
            legenda = "Valor Líquido(R$)"
          else
            legenda = "Previsão(R$)";
          if (!props.matrix.assinando_refinport && props.matrix.assinando_port)
            legenda = "Saldo Devedor(R$)";
        }

        break;
      case "valor_total":
        legenda = props.operacao.toLowerCase() == "cartao" ? "Valor do Saque(R$)" : "Valor do Empréstimo(R$)";
        break;
      case "prazo":
        legenda = "Prazo (n)";
        break;
      // case "total_geral":
      //     legenda = "Total a Pagar (R$)";
      //     break;
      case "valor_parcelas":
        legenda += " (R$)";
        break;
      case "operacao":
        legenda = "Tipo de Operação";
        break;
      case "convenio":
        legenda = "Convênio";
        break;
      case "iof":
        legenda += " (R$)";
        break;
      case "seguro":
        legenda += " (R$)";
        break;
      case "custo_efetivom":
        legenda = "CET Mensal(%)";
        break;
      case "custo_efetivo":
        legenda = "CET Anual(%)";
        break;
      case "saldo_devedor":
        legenda += " (R$)";
        break;
      default:
        legenda += " (%)";
    }
    return legenda;
  };

  const format = (value, row) => {
    if (row && row.indexOf("prazo") !== -1) {
      return value;
    }

    // if (row) {
    //     if (row.indexOf('taxa') !== -1 || row.indexOf('prazo') !== -1) {
    //         return value;
    //     }

    //     value = formatCurrency(value);
    //     return value;
    // }

    if (value && value > 0) value = formatCurrency(value);
    return value;
  };

  return (
    <>
      <table
        className={
          bordered
            ? "table c-table c-table-bordered mt-2"
            : "table c-table result-table c-table-borderless mt-2"
        }
      >
        <tbody>
          {detailed
            ? matrix[0].map(
              (row, i) =>
                dFields.indexOf(row) !== -1 &&
                (row != "seguro" || matrix[1][i] > 0) && (
                  <tr
                    className={
                      [
                        "total_geral",
                        "prazo",
                        "valor_liquido",
                        "valor_total",
                      ].indexOf(row) >= 0
                        ? "tr-custom font-weight-bold"
                        : "tr-custom"
                    }
                    key={`row-${i}`}
                  >
                    <td
                      className="detailed-td"
                      // eslint-disable-next-line
                      style={{
                        width: "50%",
                        textTransform:
                          row === "iof"
                            ? "uppercase"
                            : row === "prazo"
                              ? "normal"
                              : "capitalize",
                      }}
                    >
                      <span style={{ marginLeft: "3px" }}>
                        {getLegenda(row)}
                      </span>
                    </td>
                    <td className="text-right">
                      <span style={{ marginRight: "3px" }}>
                        {row === "convenio" || row === "operacao"
                          ? matrix[1][i]
                          : format(matrix[1][i], row, props.currency)}
                      </span>
                    </td>
                  </tr>
                )
            )
            : matrix[0].map(
              (row, i) =>
                fields.indexOf(row) !== -1 &&
                (row != "seguro" || matrix[1][i] > 0) && (
                  <tr key={`row-${i}`}>
                    <td className="custom-td font-mobile">
                      {row === "valor_liquido"
                        ? !props.operacao ||
                          props.operacao.toLowerCase() == "contrato novo"
                          ? "Valor do Crédito:"
                          : "Valor do Empréstimo:"
                        : row === "prazo"
                          ? "Quantidade Parcelas:"
                          : "Valor das Parcelas:"}{" "}
                    </td>
                    <td className="font-weight-bold text-left lead custom-td">
                      {matrix[1][i].toString().length < 4
                        ? `${matrix[1][i]} meses`
                        : "R$ " + format(matrix[1][i], null, props.currency)}
                    </td>
                  </tr>
                )
            )}
        </tbody>
      </table>
      {props.portabilidade &&
        <div className="form-group">
          <hr />
          <span className="text-center span-del d-block mt-4">
            <h6>
              "Os valores acima são estimados, podendo sofrer alterações
              após a chegada do saldo devedor."
            </h6>
          </span>
        </div>}
    </>
  );
};

export default Table;
