import React, { useState, useEffect } from "react";

import Form from "../../components/form/simulator/simulatorCartao";
import SelPort from "../../components/form/simulator/selPort";
import Result from "./result";
import { bancosCNPJ } from "../../helpers/bancos";
import { clienteBloqueado } from "../../helpers/core";

import { Buttons } from "../../components/layout";
import { swalError } from "../../components/swal";
import {
  getSession,
  getData,
  sendData,
  buscarAutorizacao,
  bancoFornecedor,
} from "../../helpers/auth";

import { uriList } from "../../helpers/config";
import { goBack } from "../../helpers/core";
import {
  formatCurrency,
  formatFloat,
  mCPF,
  getCookie,
  numerosIndividuais,
} from "../../helpers/general";
import {
  setProposta,
} from "../../store/actions/dados";
import { validate, validateCPF } from "../../helpers/validation";
import { connect } from "react-redux";

function FinanceData({ ...props }) {
  let initialState = {
    cpf: "",
    matricula: "",
    valor: 0,
    convenio_id: 0,
    tabela_id: 0,
    prazo: 0,
    tipo_simulacao: "disponivel",
    ip: getCookie({ label: "ip" }),
  };

  let alertIni = {
    cpf: 0,
    matricula: 0,
    valor: 0,
    convenio: 0,
    tabela_id: 0,
    prazo: 0,
  };

  const [msg, setMsg] = useState({ cpf: 0 });
  const [data, setData] = useState(initialState);
  const [automatico, setAutomatico] = useState(false);
  const [enviando, setEnviando] = useState(false);
  const [simulando, setSimulando] = useState(false);
  const [simulacao, setSimulacao] = useState(false);
  const [result, setResult] = useState({});
  const [dados, setDados] = useState({});
  const [selPort, setSelPort] = useState(false);
  const [carregando, setCarregando] = useState(false);
  const [convenioList, setConvenioList] = useState([]);
  const [tabelaList, setTabelaList] = useState([]);
  const [prazoSteps, setPrazoSteps] = useState(null);
  const [exibir_contratos_port, setExibir_contratos_port] = useState(false);
  const [naoGravar, setNaoGravar] = useState(false);
  const [disableCPF, setDisableCPF] = useState(false);
  const [autorizacaoInvalida, setAutorizacaoInvalida] = useState(false);
  const [selectedBanco, setSelectedBanco] = useState()
  const [disableValidate, setDisableValidate] = useState(false)
  const [carregandoDados, setCarregandoDados] = useState(false)
  const [carregouDados, setCarregouDados] = useState(false)

  const [showAlert, setAlert] = useState(alertIni);

  async function Validate() {
    // console.log("financedata - Validate") 
    setAlert(alertIni);
    let errorMsg = "Preencha os campos obrigatórios";
    let _alert = alertIni
    let result = true;

    if (!data.tabela_id) {
      result = false;
      _alert = { ..._alert, tabela_id: 1 };
    }

    if (!data.prazo) {
      result = false;
      _alert = { ..._alert, prazo: 1 };
    }

    if (!data.valor && data.tipo_simulacao != "disponivel") {
      result = false;
      _alert = { ..._alert, valor: 1 };
    }

    if (!data.cpf) {
      result = false;
      _alert = { ..._alert, cpf: 1 };
    }

    if (!data.matricula) {
      result = false;
      _alert = { ..._alert, matricula: 1 };
    }

    if (!result) {
      setAlert(_alert)
      console.log("financedata - Validate: ", _alert)
      setDisableValidate(true)
      swalError(errorMsg);
    }
    setDisableValidate(false)

    return result;
  }

  async function handleConvenio(convenio_id) {
    console.log("simulatorCartao.handleConvenio: ", convenio_id);
    setAlert(alertIni);
    setConvenio_id(convenio_id);
    _getTabelas(convenio_id);
  }

  async function _getTabelas(convenio_id) {
    // console.log("_getTabelas: ", convenio_id)
    setTabelaList([]);

    handleTabela(0);
    if (convenio_id) {
      // 12.08.2023 - Teste
      // setCarregando(true);
      console.log("simulatorCartao._getTabelas: ", convenio_id);
      await sendData({
        uri: uriList("listarTabelas"),
        content: { convenio_id, operacao: "CARTAO" },
        method: "POST",
      })
        .then((res) => {
          setCarregando(false);
          if (res.status && parseInt(res.status) > 200) throw new Error();
          // console.log("Listou tabelas: ", res)
          setTabelaList(res);
          if (res && res[0] && res[0].id) handleTabela(res[0].id);
        })
        .catch((e) => {
          setCarregando(false);
          alert("Erro ao obter Tabelas.\n" + e.message);
        });
    }
  }

  const handleTabela = (tabela_id) => {
    setAlert(alertIni);
    // console.log("HandleTabela: ", tabela_id) 
    setTabela_id(tabela_id);
    _getPrazos(tabela_id);
  };

  const handlePrazo = (prazo) => {
    setAlert(alertIni);
    setPrazo(prazo);
  };

  async function _getPrazos(tabela_id) {
    setPrazoSteps([]);
    if (tabela_id) {
      //-- teste 12.08.2023
      // setCarregando(true);
      getData({ uri: uriList("prazos") + "/" + tabela_id })
        .then((res) => {
          setCarregando(false);
          if (res.status && res.status > 200) throw new Error();

          setPrazoSteps(res);
          setPrazo(res[0].prazo);
        })
        .catch((e) => {
          setCarregando(false);
          return false;
        });
    }
  }

  function handleSelPort() {
    simular();
    setSelPort(false);
  }

  async function obterDadosCartao() {
    if (!data.cpf || !data.matricula) return false
    setCarregandoDados(true)
    setCarregouDados(false)
    setSimulacao(false)
    const content = {
      cpf: data.cpf.replace(/\D/g, ""),
      matricula: data.matricula,
      nome: data.nome || data.cpf.replace(/\D/g, ""),
    }
    await sendData({
      uri: uriList("obterDadosCartao"),
      content,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        console.log("obterDadosCartao.simular(res): ", res);
        if (res.status && parseInt(res.status) > 200) {
          setSimulacao(false);
          swalError({ title: (res.erro || res.message) || "Erro ao simular operação!" });
          return;
        }

        data.valor = res?.valor || res?.message?.valor
        data.beneficio = res?.beneficio || res?.message?.beneficio
        setCarregouDados(true)
      })
      .catch((e) => {
        let erro = JSON.stringify(e);
        console.log("Erro ao obterDadosCartao: ", erro);
        swalError({ title: "Erro ao obter dados do Cartão!\n" + erro });
      });

    setCarregandoDados(false)
  }
  async function simular(gravar = false) {
    console.log("financeData - simular");
    let newData = {};
    let result = {}
    // setNaoGravar(!contrato && props.portabilidade);

    if (!Validate()) return false;

    setSimulacao(true);
    newData.cpf = data.cpf.replace(/\D/g, "");
    newData.nome = data.nome || data.cpf.replace(/\D/g, "");
    newData.matricula = data.matricula;
    newData.valor = formatFloat(data.valor);
    newData.convenio_id = data.convenio_id;
    newData.tabela_id = data.tabela_id;
    newData.prazo = data.prazo;
    newData.tipo = data.tipo_simulacao;
    newData.gravar = gravar;
    newData.operacao = 'CARTAO'
    newData.convenio = convenioList.find((c) => c.id == data.convenio_id);
    if (gravar)
      setEnviando(true);

    console.log("simulatorCartao.simular: ", newData);
    await sendData({
      uri: uriList("simulador"),
      content: newData,
      signal: null,
      method: "POST",
    })
      .then((res) => {
        console.log("simulatorCartao.simular(res): ", res);
        if (res.status && parseInt(res.status) > 200) {
          setSimulacao(false);
          swalError({ title: (res.erro || res.message) || "Erro ao simular operação!" });
          return;
        }

        result = res;
        if (!gravar) {
          res.tipo = newData.tipo;
          setResult(res);
          setDados(newData);
          setSimulando(true);
          setSimulacao(false);
        }
      })
      .catch((e) => {
        let erro = JSON.stringify(e);
        setSimulacao(false);
        console.log("Erro ao simular Cartao: ", erro);
        swalError({ title: "Erro ao simular operação!\n" + erro });
      });

    if (gravar)
      setEnviando(false);

    return result;
  }

  async function toggleStep(n) {
    console.log(`simulatorCartao.toggleStep: ${n}`);
    if (n == 1) {
      if (props.toggleStep) {
        // const simulacao = await simular(true)
        // await acompanharProposta(simulacao)
        props.toggleStep(1, simulacao);
      }
    } else setSimulando(false);
  }

  function setConvenio_id(convenio_id) {
    console.log("financedata - setConvenio_id: ", convenio_id);
    let _data = data;
    let _convenio = {};
    console.log("financedata - convenios: ", convenioList);

    for (let i = 0; i < convenioList.length; i++) {
      _convenio = convenioList[i];
      console.log("_convenio: ", _convenio);
      if (_convenio.id == convenio_id) {
        console.log(
          "handleConvenio: (exibir_contratos_port)",
          _convenio.exibir_contratos_port
        );
        setExibir_contratos_port(_convenio?.exibir_contratos_port?.toLowerCase() == "sim");
        if (_convenio?.exibir_contratos_port?.toLowerCase() == "sim")
          setData({ ...data, contrato_portado: '', parcela_portada: 0, })

        break;
      }
    }

    _data.convenio_id = convenio_id;
    // console.log("setConvenio_id: ", _data);
    setData(_data);
  }

  function setTabela_id(tabela_id) {
    let _data = data;
    _data.tabela_id = tabela_id;

    setData(_data);
  }

  function setPrazo(prazo) {
    let _data = data;
    _data.prazo = prazo;
    // console.log("setPrazo: ", _data);
    setData(_data);
  }

  function handleChange(e, innerData) {
    setAlert(alertIni);
    let target = e.currentTarget;

    if (["matricula"].indexOf(target.name) >= 0) {
      setData({ ...data, [target.name]: target.value.replace(/\D/g, "") });
      setCarregouDados(false)
    }
    else if (["valor"].indexOf(target.name) >= 0)
      setData({ ...data, [target.name]: formatCurrency(innerData || target.value), });
    else setData({ ...data, [target.name]: target.value });
  }

  function CarregarDados(cpf) {
    console.log("simulatorCartao.CarregarDados(cpf): ", cpf);
    console.log("simulatorCartao.CarregarDados(user): ", props.user);
    if (
      !props.user ||
      props.user?.tipo === "AGENTE" ||
      props.user?.tipo === "CLIENTE"
    )
      return;
    setCarregando(true);
    sendData({
      uri: uriList("pessoa"),
      content: { cpf },
      signal: null,
      method: "POST",
    })
      .then((res) => {
        if (res.status && res.status > 200) throw new Error(res.message);

        setData({ ...data, cpf, matricula: res?.data?.matricula });

        setCarregando(false);
      })
      .catch((e) => {
        setCarregando(false);
        // setData({ ...getDataIni(), cpf })
      });

    console.log("simulatorCartao.CarregarDados(consultando restrições)");
    setDisableValidate(false)
    sendData({
      uri: uriList("consultarRestricoes"),
      content: { cpf },
      signal: null,
      method: "POST",
    })
      .then((res) => {
        console.log("simulator.data - consulta de restrições: ", res);

        // text: res.message + " " + JSON.stringify(res.data),
        if (res.status && res.status > 200) {
          setDisableValidate(true)
          swalError({ title: "Operação não poderá ser iniciada, favor entrar em contato com a Plataforma." });
        }
      })
      .catch((e) => {
        console.log("simulator.data - consulta de restrições(erro): ", e);
        console.log(e);
      });
  }
  const handleCpf = async (e) => {
    setAutorizacaoInvalida(false);
    setAlert(alertIni);
    setDisableCPF(false);
    setMsg({ ...msg, cpf: 0 });
    setCarregouDados(false)
    let nums = /\d/;
    let cpf = e.currentTarget.value;
    let valid = true;
    if (nums.test(e.currentTarget.value)) {
      cpf = mCPF(e.currentTarget.value);
      setData({ ...data, cpf });
      if (cpf.length >= 14) {
        valid = validateCPF(cpf);
        if (!valid) setMsg({ ...msg, cpf: 1 });
        else {
          CarregarDados(cpf);
          // await sendData({
          //   uri: uriList("consultarRestricoes"),
          //   content: { cpf },
          //   method: "POST",
          // })
          //   .then((res) => {
          //     if (res.status && parseInt(res.status) > 200) {
          //       setDisableCPF(true);
          //       console.log("Erro no CPF: ", res);
          //       swalError({ title: res.message });
          //       return false;
          //     }
          //   })
          //   .catch((error) => {
          //     console.log("Erro ao consultar Restrições: ", error);
          //   });
        }
      }

      return false;
    } else if (!e.currentTarget.value) setData({ ...data, cpf: "" });

    return false;
  };

  useEffect(() => {
    console.log("financedata - useEffect(1)");
    console.log("financedata (simulator) - useEffect: ", props.portabilidade);
    if (!convenioList?.length) {
      // teste 12.08.2023
      // setCarregando(true);
      // sendData({ uri: uriList("convenios") })
      sendData({
        uri: uriList("listarConveniosCartao"),
        content: { retirarTabelas: true },
        method: "POST",
      })
        .then((res) => {
          setCarregando(false);
          if (res.status && res.status > 200) throw new Error();
          if (!Array.isArray(res)) throw new Error();
          setConvenioList(res);
          handleConvenio(res[0].id);
          console.log("SimulatorCartao.useEffect(1): ", res);
          if (res && res.length == 1) {

            console.log("Diogo Teste: ", res[0].exibir_contratos_port);
            setExibir_contratos_port(res[0]?.exibir_contratos_port?.toLowerCase() == "sim");
            if (res[0]?.exibir_contratos_port?.toLowerCase() == "sim")
              setData({ ...data, contrato_portado: '', parcela_portada: 0, })
          }

          if (res && res[0]) handleConvenio(res[0].id);
        })
        .catch((e) => {
          setCarregando(false);
          setConvenioList([]);
        });
    }

    // 12.08.2023 - Soh busca registro se existir proposta
    if (props.proposta) {
      setAlert(alertIni);
      setSimulacao(false);
      getData({ uri: uriList("propostacmp") + "/" + props.proposta })
        .then((res) => {
          if (res.status && res.status > 200) throw new Error(res.message);

          // console.log("Resultado: ", res)
          setAutomatico(res.automatico);
          for (let d in data) {
            if (res[d]) {
              if (
                [d] != "parcelas_total" &&
                [d] != "id" &&
                [d] != "proposta_uuid"
              )
                setData((previous) => ({
                  ...previous,
                  [d]: formatCurrency(formatFloat(res[d])),
                }));
              else setData((previous) => ({ ...previous, [d]: res[d] }));
            }
          }
        })
        .catch((e) => {
          setData(initialState);
          console.log("Erro ao obter dados complementares da proposta!");
          console.log("Dados: ", data);
          let storeData = getSession({ key: "bancarios" });

          if (storeData) setData(storeData);
        });
    }
    // eslint-disable-next-line
  }, []);


  return (
    <>
      {simulando && (
        <Result
          title="Simulação"
          toggleStep={toggleStep}
          result={{ ...dados, matricula: data.matricula || "" }}
          data={{ ...result, matricula: data.matricula || "" }}
          admin={true}
          naoGravar={naoGravar}
          simulacao={{ ...simulacao, matricula: data.matricula || "" }}
          disabledSecond={naoGravar}
          dadosPort={data}
          portabilidade={props.portabilidade}
        />
      )}

      {!simulando && !enviando && (
        <fieldset className="col-12">
          <div id="simulation-financedata">
            <h4
              className="display-4 text-center font-weight-bold"
              style={{ marginBottom: "15px", color: "#3E6C84" }}
            >
              Simulador Cartão
            </h4>

            <Form
              data={data}
              change={handleChange}
              warning={showAlert}
              detalhes={props.detalhes}
              automatico={automatico}
              portabilidade={props.portabilidade}
              setConvenio_id={setConvenio_id}
              setTabela_id={setTabela_id}
              setPrazo={setPrazo}
              enviando={enviando}
              handleCpf={handleCpf}
              msg={msg}
              simulacao={simulacao}
              convenioList={convenioList}
              tabelaList={tabelaList}
              prazoSteps={prazoSteps}
              carregando={carregando || carregandoDados}
              handleConvenio={handleConvenio}
              handleTabela={handleTabela}
              handlePrazo={handlePrazo}
              handleChange={handleChange}
              exibir_contratos_port={exibir_contratos_port}
              selectedBanco={selectedBanco}
              obterDadosCartao={obterDadosCartao}
              carregouDados={carregouDados}
            />


            <Buttons
              goBack={() => {
                setAlert(alertIni);
                setSimulacao(false);
                // goBack(data, "financeiro", props.toggleStep);
              }}
              submit={async () => {
                setAlert(alertIni);
                handleSelPort(data)
              }}
              disabledFirst={true}
              disabledSecond={disableCPF || disableValidate || !carregouDados}
            />
          </div>
        </fieldset>
      )}
      {enviando && (
        <div style={{ textAlign: "center", width: "100%" }}>
          <br />
          <h2 style={{ opacity: "60%" }}>Aguarde, gravando dados...</h2>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    detalhes: state.dados.detalhes,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
  };
}
export default connect(mapStateToProps, mapActionToProps)(FinanceData);
