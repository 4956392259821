import React, { useState, useEffect } from "react";

import Form from "../../components/form/simulator/complement_card";

import { Buttons } from "../../components/layout";
import { mDate, getCookie, mTel, mCPF } from "../../helpers/general";
import { sendData, setSession } from "../../helpers/auth";
import { obterDominio } from "../../helpers/original";
import { uriList } from "../../helpers/config";
import { isInvalid, send, goBack } from "../../helpers/core";
import { setTDoc } from "../../store/actions/dados";

import { connect } from "react-redux";

function Complement({ ...props }) {
  const [escolaridade, setEscolaridade] = useState(obterDominio("ESCOLARIDADE"));
  const [profissao, setProfissao] = useState(obterDominio("PROFISSAO"));

  const [data, setData] = useState({
    escolaridade: escolaridade[0].CODIGO,
    profissao: profissao[0].CODIGO,
    peso: 0.0,
    altura: 0.0,
    telefone_comercial: "",
    id: props.cliente,
    ip: getCookie({ label: "ip" }),
    proposta_uuid: props.proposta,
  });

  function handleChange(e, innerData) {
    let target = e.currentTarget;
    let value = innerData || target.value;

    setData({ ...data, [target.name]: value });
  }

  async function submit(callback) {
    let newData = Object.assign(data);

    for (let n in newData) {
      if (newData[n] && typeof newData[n] === "string")
        newData[n] = newData[n].toUpperCase();
    }

    console.log("ComplementCard.submit: ", newData);
    await sendData({
      uri: uriList("pessoas"),
      content: { ...newData, proposta_uuid: props.proposta, },
      method: "POST",
    })
      .then((res) => {
        if (res.status && parseInt(res.status) > 200) throw new Error();
        setSession({
          obj: newData,
          key: "complementocard",
        });

        props.toggleStep(1);
        props.alterarTDoc(data.t_doc);
      })
      .catch((e) => {
        if (callback) callback(false);

        alert("Erro ao gravar dados.");
      });

    for (let d in data) props.clienteDados[d] = data[d];

    return false;
  }

  useEffect(() => {
    console.log("ComplementCard Sicoob - UseEffect: ", props.clienteDados);
    for (let d in data)
      if (props.clienteDados[d])
        if (d == "data_admissao") {
          let date = new Date(props.clienteDados[d]);
          if (!isNaN(date)) date = date.toLocaleDateString();
          else date = mDate(props.clienteDados[d]);

          setData((previous) => ({ ...previous, [d]: date }));
        } else
          setData((previous) => ({ ...previous, [d]: props.clienteDados[d] }));
  }, []);

  return (
    <fieldset className="col-12">
      <h4
        className="display-4 text-center font-weight-bold"
        style={{ marginBottom: "15px", color: "#3E6C84" }}
      >
        {props.title}
      </h4>
      <div className="form">
        <Form
          data={data}
          handle={handleChange}
          escolaridade={escolaridade}
          profissao={profissao}
        />
      </div>

      <Buttons
        goBack={() => goBack(data, "complementocard", props.toggleStep)}
        submit={submit}
      />
    </fieldset>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    clienteDados: state.dados.clienteDados,
  };
}

function mapActionToProps(dispatch) {
  return {
    alterarTDoc(t_doc) {
      dispatch(setTDoc(t_doc));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Complement);
