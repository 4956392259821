import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import "../sass/style.scss";

import Topbar from "../components/layout/topbar";
// import * as PendenciasM from "../components/layout/pendencias";
import Sidebar from "../components/layout/sidebar";
import Footer from "../components/layout/footer";
import Dashboard from "../components/layout/dashboard";
import ContextMenu from "../components/context";
import Modal from "../components/modal";
import Propostas from "../views/admin/propostas";
import Pessoas from "../views/admin/pessoas";
import { contextMenuOptions, } from "../helpers/general";
import { uriList } from "../helpers/config";
import { sendData } from "../helpers/auth";
import Convenios from "../views/admin/convenios";
import Emails from "../views/admin/emails";
import Tabelas from "../views/admin/tabelas";
import Coeficientes from "../views/admin/coeficientes";
import Configuracoes from "../views/admin/configuracoes";
import Pendencias from "../views/admin/pendencias";
import Retencoes from "../views/admin/retencoes";
import Reapresentacoes from "../views/admin/reapresentacoes";
import Solicitacoes from "../views/admin/solicitacoes";
import NovaProposta from "./propostaAgente";
import CreditoPessoal from "./creditoPessoal";
import Usuarios from "../views/admin/usuarios";
import Refinanciamento from "./refinanciamento";
import Portabilidade from "./portabilidade";
import Cartao from "./cartao";
import Fgts from "./fgts";
import CalculadoraMargem from "./calculadoraMargem";
import AutorizacaoINSS from "./autorizacaoINSS";
import Documentos from "../views/admin/documentos";
import AutorizacaoSicoob from "./autorizacaoSicoob";
import { connect } from "react-redux";
// import { setUser, } from '../store/actions/dados'
// import { TOKEN_USER } from '../helpers/config';
import { setUser, setPendencias } from "./../store/actions/dados";
import Checkpoints from "../components/form/admin/Conformidade/Checkpoints";
import Checklists from "../components/form/admin/Conformidade/Checklists";
import PreencherChecklist from "../components/form/admin/Conformidade/PreencherChecklist";
import Portabilidades from "../views/admin/portabilidades";
import Charts from "../views/admin/Dashboard/charts";

const defaultContext = {
  display: {
    up: false,
  },

  options: "",
};

function Admin(props) {
  const path = window?.location?.pathname;

  const [contextMenu, setContextMenu] = useState(false);
  const [modal, setModal] = useState(false);
  const [detailedModal, setDetailedModal] = useState({
    title: "",
    text: [],
  });
  const [action, setAction] = useState("");
  const [checklist, setChecklist] = useState(false);

  async function toggleMenuOptions(_proposta) {
    const multSelect = _proposta?.multSelect?.length > 1
    console.log("admin.toggleMenuOptions(1)", { multSelect });
    // console.log("toggleMenuOptions...")
    // let proposta = await getProposta()
    let result = [...contextMenuOptions];
    let posicao, antifraude;
    if (_proposta) {
      posicao = _proposta.posicao.toLowerCase();
      antifraude = _proposta.antifraude;
    }

    result.map((option, i) => {
      if (multSelect && option.multSelect) {
        option.enabled = !option.enableFunction || option?.enableFunction({ ..._proposta, user: props.user }, option.enabled, props.filtrosPropostas);
      }
      else {
        option.enabled =
          props.user &&
          (!option.positions || option.positions.indexOf(posicao) >= 0) &&
          (!option.positionsEx || option.positionsEx.indexOf(posicao) < 0) &&
          (!option.enableUser ||
            option.enableUser.indexOf(props?.user?.tipo) >= 0) &&
          (!option.disableUser ||
            option.disableUser.indexOf(props?.user?.tipo) < 0) &&
          (!option.tipoUsuarioEx ||
            option.tipoUsuarioEx.indexOf(props.user?.tipo.toLowerCase()) < 0) &&
          (option.action != "antifraude" || !antifraude);

        if (option.enableFunction)
          option.enabled = option.enableFunction({ ..._proposta, user: props.user }, option.enabled, props.filtrosPropostas);

        if (option.enableUser) option.enabled = option.enableUser(props.user);
      }
    });

    return result;
  }

  async function toggleContextMenu(e, display, proposta) {
    let x = e.pageX || 0;
    let y = e.pageY || 0;

    const { innerHeight } = window;

    let _mult = 25 //-- Configuração da altura do menu suspenso
    if (proposta?.multSelect?.length > 1)
      _mult = 12

    //-- Posicionamento do menu suspenso(Context Menu)
    //-- Obtendo menus disponiveis
    let _contextMenuOptions;
    if (display) _contextMenuOptions = await toggleMenuOptions(proposta);

    const posY = () => {
      let _const = _contextMenuOptions.filter((el) => el.enabled).length * _mult;
      let contextBottom = y + _const;
      let windowBottom = window.innerHeight + window.pageYOffset;

      // console.log(`toggleContextMenu..: (y)${y} (const): ${}`)
      if (contextBottom >= windowBottom) {
        y = y - _const;
      }

      return y;
    };

    const posX = () => {
      const _const = 160  //-- Configuração da base do menu suspenso

      let contextRight = x + _const;

      if (contextRight > window.innerWidth) {
        x = x - _const;
      }


      return x;
    };

    if (y && display) {
      y = posY();
    }

    if (x && display) {
      x = posX();
    }

    setContextMenu({
      display: {
        up: display,
        x,
        y,
      },

      options: _contextMenuOptions,
    });
  }

  const pathList = (path, props) => {
    return (
      {
        "/admin": {
          title: "Fila de Propostas",
          content: <Propostas context={toggleContextMenu} checklist={checklist} action={action} />,
        },

        "/admin/esteira/portabilidade": {
          title: "Esteira - Portabilidade",
          content: <Portabilidades context={toggleContextMenu} checklist={checklist} action={action} operacao={"PORTABILIDADE"} />,
        },
        "/admin/esteira/novo": {
          title: "Esteira - Contrato Novo",
          content: <Portabilidades context={toggleContextMenu} checklist={checklist} action={action} operacao={"CONTRATO NOVO"} />,
        },
        "/admin/esteira/refinanciamento": {
          title: "Esteira - Refinanciamento",
          content: <Portabilidades context={toggleContextMenu} checklist={checklist} action={action} operacao={"REFINANCIAMENTO"} />,
        },

        "/admin/pessoas": {
          title: "Pessoas",
          content: <Pessoas />,
        },
        "/admin/dashboard": {
          title: "Dashboard",
          content: <Charts />,
        },

        "/admin/convenios": {
          title: "Convênios",
          content: <Convenios />,
        },

        "/admin/novaProposta": {
          title: "Nova Proposta",
          content: <NovaProposta novaProposta={true} />,
        },
        "/admin/creditoPessoal": {
          title: "Crédito Pessoal",
          content: <CreditoPessoal novaProposta={true} />,
        },
        "/admin/Refinanciamento": {
          title: "Refinanciamento",
          content: <Refinanciamento />,
        },
        "/admin/Portabilidade": {
          title: "Portabilidade",
          content: <Portabilidade />,
        },
        "/admin/Cartao": {
          title: "Cartão",
          content: <Cartao />,
        },
        "/admin/calculadoraMargem": {
          title: "Calculadora de Margem",
          content: <CalculadoraMargem />,
        },

        "/admin/autorizacaoINSS": {
          title: "Autorização Inss",
          content: <AutorizacaoINSS />,
        },

        "/admin/documentos": {
          title: "Documentos Gerais",
          content: <Documentos />,
        },
        "/admin/autorizacaoSicoob": {
          title: "Autorização Sicoob",
          content: <AutorizacaoSicoob />,
        },

        "/admin/Fgts": {
          title: "FGTS",
          content: <Fgts />,
        },

        "/admin/configuracoes": {
          title: "Configurações",
          content: <Configuracoes />,
        },

        "/admin/pendencias": {
          title: "Pendências",
          content: <Pendencias />,
        },

        "/admin/retencoes": {
          title: "Retenções",
          content: <Retencoes />,
        },

        "/admin/reapresentacoes": {
          title: "Reapresentação de TED",
          content: <Reapresentacoes />,
        },

        "/admin/solicitacoes": {
          title: "Solicitações",
          content: <Solicitacoes />,
        },

        "/admin/emails": {
          title: "Emails",
          content: <Emails />,
        },

        "/admin/tabelas": {
          title: "Tabelas",
          content: <Tabelas />,
        },

        "/admin/coeficientes": {
          title: "Coeficientes",
          content: <Coeficientes />,
        },

        "/admin/checkpoint": {
          title: "CheckPoints",
          content: <Checkpoints />,
        },

        // "/admin/preencherChecklist": {
        //   title: "Preencher Checklist",
        //   content: <PreencherChecklist />,
        // },

        "/admin/checklist": {
          title: "CheckLists",
          content: <Checklists />,
        },

        "/admin/usuarios": {
          title: "Usuários",
          content: <Usuarios />,
        },
      }[path] || { title: "404", content: "Página não existe" }
    );
  };

  useEffect(() => {
    if (contextMenu) {
      let action = localStorage.getItem("action");
      setAction(action);
      localStorage.removeItem("action");

      let _checklist = localStorage.getItem("checklist");
      setChecklist(_checklist);
      localStorage.removeItem("checklist");
    }

  }, [contextMenu]);

  return (
    <>
      <main
        onClickCapture={(e) => toggleContextMenu(defaultContext, false)}
        id="wrapper"
      >
        <Topbar />
        <Sidebar phone={process.env.REACT_APP_WHATS} />
        <div className="content-page">
          <div
            style={{
              backgroundColor: "#f7f7f2",
              minHeight: window.innerHeight + "px",
            }}
            className="content"
          >
            <div className="page-content-wrapper">
              <div className="container-fluid">
                <Dashboard title={pathList(path).title} />
                {pathList(path).content}
                {/* {width() === 'mobile' ? pathList(path).content: ""} */}
                <ContextMenu
                  display={contextMenu.display}
                  options={contextMenu.options}
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
        {modal && (
          <Modal
            title={detailedModal.title}
            text={detailedModal.text}
            page={detailedModal.page}
            onClick={() => setModal(false)}
          />
        )}
      </main>
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.dados.user,
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    pendencias: state.dados.pendencias,
    filtrosPropostas: state.dados.filtrosPropostas,
  };
}

export default withRouter(connect(mapStateToProps,)(Admin));
