const crypto = require('crypto');

const secretSalt = 'inContaDigital@4321'
const cChaveSecretaCpf = 'Set@C8433aa$$$1ac'
const cIvBase = '@#$-14-ct15'

let cipher = salt => {
    let textToChars = text => text.split('').map(c => c.charCodeAt(0))
    let byteHex = n => ("0" + Number(n).toString(16)).substr(-2)
    let applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code)

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('')
}

let decipher = salt => {
    let textToChars = text => text.split('').map(c => c.charCodeAt(0))
    let saltChars = textToChars(salt)
    let applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code)
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('')
}

export function criptografar(texto) {
    if (!texto)
        return
    let myCipher = cipher(secretSalt)
    return myCipher(texto)
}

export function descriptografar(texto) {
    if (!texto)
        return
    let myDecipher = decipher(secretSalt)
    return myDecipher(texto)    // --> 'the secret string'
}

export function criptografarCPF(cpf) {
    const chaveSecreta = crypto.createHash('sha256').update(cChaveSecretaCpf).digest();
    const iv = crypto.createHash('md5').update(cIvBase).digest();

    const cipher = crypto.createCipheriv('aes-256-cbc', chaveSecreta, iv);
    let encrypted = cipher.update(cpf, 'utf8', 'base64');
    encrypted += cipher.final('base64');

    // Substituir caracteres inválidos para URLs
    const encryptedUrlSafe = encrypted
        .replace(/\+/g, '-')  // Substitui '+' por '-'
        .replace(/\//g, '_')  // Substitui '/' por '_')
        .replace(/=+$/, '');  // Remove '=' no final

    return encryptedUrlSafe;
}

// Função para descriptografar o CPF
export function descriptografarCPF(encryptedCPF) {
    const chaveSecreta = crypto.createHash('sha256').update(cChaveSecretaCpf).digest();
    const iv = crypto.createHash('md5').update(cIvBase).digest();

    // Reverter as substituições para os caracteres válidos em base64
    const encrypted = encryptedCPF
        .replace(/-/g, '+')  // Substitui '-' de volta para '+'
        .replace(/_/g, '/'); // Substitui '_' de volta para '/'

    const decipher = crypto.createDecipheriv('aes-256-cbc', chaveSecreta, iv);
    let decrypted = decipher.update(encrypted, 'base64', 'utf8');
    decrypted += decipher.final('utf8');

    return decrypted;
}
