import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "reactstrap";
import { connect } from "react-redux";

import { sendData, getData } from "../../../../helpers/auth";
import './checklist.css'
import { uriList } from "../../../../helpers/config";
import { width } from "../../../../helpers/general";
import CompConformidade from "./CompConformidade";
import {
    Aguarde,
} from "../../../../helpers/general";
import { confirmSwal, swalError } from "../../../swal";

function ShowConformidade(props) {
    const [loaded, setLoaded] = useState(false)
    const [aprovada, setAprovada] = useState(props.detalhes.conformidadeapv)
    const [gerandoRelatorio, setGerandoRelatorio] = useState(false)

    const handleClose = (executed = false) => {
        console.log("ShowConformidade.handleClose: ", props)
        if (executed && props.callBack)
            props.callBack()

        if (props.handleCloseEv)
            props.handleCloseEv()
    }

    const handleAprovar = async (aprovar) => {
        async function callback(e) {
            if (!e) return false;

            await sendData({
                uri: uriList('aprovarConformidade'),
                content: { proposta_uuid: props?.proposta, aprovada: aprovar },
                signal: null,
                method: "POST",
            })
                .then(async (res) => {
                    console.log("ShowConformidade.handleAprovar: ", res)
                    if (res?.status === 200)
                        setAprovada(aprovar ? 'sim' : 'nao')
                })
                .catch((e) => {
                    console.log(e);
                    return false;
                });
        }

        confirmSwal({ callback, title: "Confirma a execução do processo?" });
    }

    const handleLiberacaoAdm = async (aprovar) => {
        async function callback(e) {
            if (!e) return false;

            await sendData({
                uri: uriList('liberarPropostaAdm'),
                content: { proposta_uuid: props?.proposta, liberar: true },
                signal: null,
                method: "POST",
            })
                .then(async (res) => {
                    console.log("ShowConformidade.handleLiberacaoAdm: ", res)
                    // if (res?.status === 200)
                    //     setAprovada(aprovar ? 'sim' : 'nao')
                })
                .catch((e) => {
                    console.log(e);
                    return false;
                });
        }

        confirmSwal({ callback, title: "Confirma a execução do processo?" });
    }

    const handleGerarRelatorio = async () => {
        console.log("[PreencherConformidade.handleGerarRelatorio(0)]");
        setGerandoRelatorio(true);
        // setAguarde(true)
        await sendData({
            uri: uriList('gerarRelatorioAuditoria'),
            content: { proposta_uuid: props?.proposta, cpf: props?.detalhes?.cpf },
            signal: null,
            method: "POST",
        })
            .then((res) => {
                console.log("PreencherConformidade.handleGerarRelatorio(1): ", res);
                if (res?._status > 200) {
                    swalError({ title: "Erro ao gerar relatório", text: res.message || 'Erro ao gerar relatório...' });
                    throw new Error();
                }

                // Convert the base64 string to a Blob
                const base64String = res.data;
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);

                // Create a Blob from the byteArray
                const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

                // Create a link element
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'relatorio_auditoria.docx';

                // Append the link to the document body and trigger the click event
                document.body.appendChild(link);
                link.click();

                // Remove the link from the document
                document.body.removeChild(link);

                setGerandoRelatorio(false);
            })
            .catch((e) => {
                console.log("[PreencherConformidade.handleGerarRelatorio(error)]: ", e);
                setGerandoRelatorio(false);
                return false;
            });
        setGerandoRelatorio(false);
    };

    return (
        <>
            {gerandoRelatorio && <Aguarde legenda={"Aguarde, gerando relatório..."} />}
            <Modal
                size={loaded ? "lg" : "sm"}
                show={true}
                centered
                onHide={(e) => {
                    handleClose(false);
                }}
                animation={false}
                scrollable
                backdrop="static"
            >
                <div className="p-1" style={{ borderBottom: "0.3px solid #CCC" }}>
                    <h4 style={{ marginLeft: "5px" }}>Conformidade</h4>
                </div>
                <div style={{ width: "100% !important", height: "100% !important", padding: "10px" }}>
                    <CompConformidade handleLoaded={() => setLoaded(true)} setConformidadeApv={setAprovada} />
                </div>

                {loaded &&
                    <div style={{ display: "flex", flexDirection: "column", borderTop: "0.3px solid #CCC" }}>
                        <div className="p-1" style={{
                            display: "flex",
                            flexDirection: width() === "mobile" ? "column" : "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "10px",
                            marginBottom: width() === "mobile" ? "15px" : "2px",
                            paddingBottom: width() === "mobile" ? "15px" : "2px"
                        }}>
                            <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
                                {
                                    <>
                                        {!aprovada &&
                                            <Button color="success" onClick={() => handleAprovar(true)}
                                                title={"Aprovar a conformidade da proposta"}
                                                style={{ minWidth: "90px" }}>
                                                Aprovar
                                            </Button>}
                                        {
                                            !props.detalhes.liberacao_adm &&
                                            ['ADMINISTRADOR', 'OPERADOR'].indexOf(props?.user?.tipo) >= 0 &&
                                            <Button color="success" onClick={() => handleLiberacaoAdm(true)}
                                                title={"Liberação administrativa da proposta"}
                                                style={{ minWidth: "90px" }}>
                                                {width() == "mobile" ? "Lib. Adm" : "Liberação Adm"}
                                            </Button>
                                        }
                                        {!aprovada &&
                                            <Button color="danger" onClick={() => handleAprovar(false)}
                                                title={"Recusar a conformidade da proposta"}
                                                style={{ minWidth: "90px" }}>
                                                Recusar
                                            </Button>}
                                    </>
                                }
                            </div>

                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                {aprovada &&
                                    <>
                                        {aprovada == 'sim' &&
                                            <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                                                <span className="text-success">Conformidade Aprovada</span>
                                            </div>}
                                        {aprovada == 'nao' &&
                                            <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                                                <span className="text-danger">Conformidade Não Aprovada</span>
                                            </div>}
                                    </>
                                }
                            </div>

                            <div style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "5px",
                                marginTop: width() === "mobile" ? "5px" : "0"
                            }}>
                                <Button color="primary" onClick={() => handleGerarRelatorio()}
                                    title="Clique para gerar o relatório de auditoria"
                                    style={{ minWidth: "90px", height: "38px" }}>
                                    Relatório
                                </Button>
                                <Button color="secondary" onClick={() => handleClose(false)}
                                    style={{ minWidth: "90px", height: "38px" }}>
                                    Fechar
                                </Button>
                            </div>
                        </div>
                    </div>
                }
            </Modal >
        </>
    );
}

function mapStateToProps(state) {
    return {
        proposta: state.dados.proposta,
        detalhes: state.dados.detalhes,
        user: state.dados.user,
    };
}

export default connect(mapStateToProps,)(ShowConformidade)



