import React from "react";

import { InputAlert, InputLabelAlert } from "../../layout";

const Form = ({ ...props }) => (
  <>
    <div className="row">
      <div className="form-group col-md-6">
        <label htmlFor="escolaridade" className="no-margin">
          Escolaridade
        </label>
        <select
          id="escolaridade"
          name="escolaridade"
          value={props?.data?.escolaridade}
          onChange={props.handle}
          autoFocus={true}
          style={{
            borderColor: props?.warning?.escolaridade ? "red" : "",
            textAlign: "left",
          }}
          className="form-control custom-select"
        >
          {props?.escolaridade &&
            props.escolaridade?.map((element, i) => (
              <option key={`${element.CODIGO}${i}}`} value={element.CODIGO}>
                {element.DESCRICAO}
              </option>
            ))}
        </select>
      </div>
      <div className="form-group col-md-6">
        <label htmlFor="profissao" className="no-margin">
          Profissão
        </label>
        <select
          id="profissao"
          name="profissao"
          value={props.data.profissao}
          onChange={props.handle}
          style={{
            borderColor: props?.warning?.profissao ? "red" : "",
            textAlign: "left",
          }}
          className="form-control custom-select"
        >
          {props?.profissao &&
            props?.profissao?.map((element, i) => (
              <option key={`${element.CODIGO}${i}}`} value={element.CODIGO}>
                {element.DESCRICAO}
              </option>
            ))}
        </select>
      </div>
    </div>

    <div className="row">
      <div className="form-group col-md-4">
        <InputLabelAlert
          type="numeric"
          label="Peso"
          id="peso"
          value={props.data.peso}
          step="0.010"
          handle={props.handle}
          onBlur={props.onBlur}
          focus={false}
        />
      </div>
      <div className="form-group col-md-4">
        <InputLabelAlert
          type="numeric"
          label="Altura"
          id="altura"
          value={props.data.altura}
          step="0.010"
          handle={props.handle}
          onBlur={props.onBlur}
          focus={false}
        />
      </div>
    </div>
  </>
);

export default Form;
