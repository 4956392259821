import React, { useState, useEffect } from "react";
import SimulatorLayout from "../components/layout";

import Simulator from "../views/simulator/simulator";
import Result from "../views/simulator/result";
import Data from "../views/simulator/data";
import Address from "../views/simulator/address";
import Contact from "../views/simulator/contact";
import Complement from "../views/simulator/complement";
import ComplementOriginal from "../views/simulator/complement_original";
import ComplementSicoob from "../views/simulator/complement_sicoob";
import Bank from "../views/simulator/bank";
import Documents from "../views/simulator/documents";
import FinanceData from "../views/simulator/financedata";
import { sendData, getItemCrypt, setItemCrypt } from "../helpers/auth";
import { uriList, TOKEN_USER } from "../helpers/config";
import Status from "../routes/status";
import Confirm from "../views/simulator/confirm";

import { connect } from "react-redux";
import {
  setUser,
  setAlterarProposta,
  setCliente,
  setProposta,
} from "../store/actions/dados";
import ComplementCard from "../views/simulator/complement_card";

function Main({ ...props }) {
  const [alterar, setAlterar] = useState(props.alterarProposta);
  const [refin, setRefin] = useState(false);
  const [status, setStatus] = useState(props.detalhes.posicao);
  const [enviando, setEnviando] = useState(false);
  const [step, setStep] = useState({
    cur: 0,
    maxStep: 9,
  });

  useEffect(() => {
    // let strToken = localStorage.getItem(TOKEN_USER);
    let strToken = getItemCrypt(TOKEN_USER);
    if (strToken) {
      let user = JSON.parse(strToken);
      props.alterarUser(user);

      //-- Setando cliente com null caso seja nova digitação
      if (!props.novaProposta && props.proposta && props.alterarProposta) {
        setStep({ ...step, cur: 2 });
      }

      if (props.novaProposta) {
        props.setProposta("limpar");
        props.setAlterarProposta(false, props.detalhes);
        props.alterarCliente(null);
      }
    }
  }, []);

  useEffect(() => {
    if (
      props.detalhes &&
      props.detalhes.operacao &&
      props.detalhes.operacao.toLowerCase() === "refinanciamento"
    ) {
      setRefin(true);
    }

    //-- Diogo 15/12/2021 - Ao acessar uma proposta que estiver paga, não permite alteração
    if (
      props.detalhes &&
      props.detalhes.posicao &&
      props.detalhes.posicao.toLowerCase() === "pago"
    ) {
      setAlterar(false);
      setStep({ ...step, cur: step.maxStep });
    }
  }, [props.detalhes]);

  const toggleStep = async (n) => {
    if (!n) n = 1;
    const _pular_complemento = () => {
      if (
        _step == 7 &&
        props.detalhes.tipo_convenio !== "CARTAO" &&
        (!props.detalhes.tela_complementar ||
          props.detalhes.tela_complementar == "NAO")
      )
        _step += n;
    };

    const _pular_refinport = () => {
      // console.log("Pular Refinport: ", props.detalhes.operacao);
      // if (
      //   _step == 8 &&
      //   ["portabilidade", "refinanciamento"].indexOf(
      //     props.detalhes?.operacao?.toLowerCase()
      //   ) < 0
      // )
      //   _step += n;
    };

    let _step = step.cur + n;
    console.log("propostaAgente.toggleStep(_step): ", _step);
    console.log("propostaAgente.toggleStep(status): ", status);
    // console.log("propostaAgente.toggleStep(detalhes): ", props.detalhes);
    if (
      step.cur >= step.maxStep &&
      (status?.toLowerCase() === "simulacao" ||
        props.detalhes?.posicao?.toLowerCase() == "simulacao" ||
        !status)
    ) {
      // console.log("propostaAgente.toggleStep(maxSte)(0)");
      if (["AGENTE"].indexOf(props.user?.tipo) >= 0)
        return setStep({ ...step, cur: 0 });

      // console.log("propostaAgente - Ultima Etapa: ", props.detalhes);
      if (
        !props.detalhes?.envio_assinatura ||
        props.detalhes?.envio_assinatura != "MANUAL"
      ) {
        console.log(
          "propostaAgente.toggleStep(Enviando para assinatura): ",
          props.proposta
        );
        setEnviando(true);

        //-- Enviando proposta para cliente
        await sendData({
          uri: uriList("enviarParaAssinatura"),
          content: { proposta_uuid: props.proposta, sms: true },
          method: "POST",
        })
          .then((res) => {
            console.log("propostaAgente.toggleStep(ok): ", res);
            setEnviando(false);
            if (res.status && parseInt(res.status) > 200) throw new Error();
          })
          .catch((e) => {
            console.log("propostaAgente.toggleStep(erro): ", e);
            setEnviando(false);
          });
      }

      // if (props.buscarPropostas) props.buscarPropostas();
      // return setStep({ ...step, cur: 0 });
    }

    //-- Fazendo busca nas propostas
    if (step.cur >= step.maxStep) {
      // console.log("Buscando propostas...");
      if (props.buscarPropostas) {
        props.buscarPropostas();
      }

      setStep({ ...step, cur: 0 });
    } else {
      if (n > 0) {
        _pular_complemento();
        _pular_refinport();
      } else {
        _pular_refinport();
        _pular_complemento();
      }

      setStep({ ...step, cur: _step });
    }
  };

  function jumpToStep(n) {
    setStep({ ...step, cur: 1 });
  }

  const RenderComplement = () => {
    return (
      <>
        {props.detalhes.tipo_convenio == "CARTAO" && (
          <ComplementCard title="Complemento Cartão" toggleStep={toggleStep} admin={true} />
        )}
        {props.detalhes && props.detalhes.tela_complementar == "SICOOB" && (
          <ComplementSicoob
            title="Complemento Sicoob"
            toggleStep={toggleStep}
            admin={true}
          />
        )}
        {props.detalhes && props.detalhes.tela_complementar == "ORIGINAL" && (
          <ComplementOriginal
            title="Complemento Original"
            toggleStep={toggleStep}
            admin={true}
          />
        )}
      </>
    );
  };
  const RenderScreen = ({ ...props }) =>
  ({
    0: (
      <Simulator
        title="Simulador"
        toggleStep={toggleStep}
        jump={jumpToStep}
        admin={true}
      />
    ),

    1: <Result title="Simulação" toggleStep={toggleStep} admin={true} />,

    2: <Data title="Dados Pessoais" toggleStep={toggleStep} admin={true} />,

    3: <Address title="Endereço" toggleStep={toggleStep} admin={true} />,

    4: <Contact title="Contato" toggleStep={toggleStep} admin={true} />,

    5: <Bank title="Dados Bancários" toggleStep={toggleStep} admin={true} />,

    6: (
      <Complement title="Complemento" toggleStep={toggleStep} admin={true} />
    ),

    7: <RenderComplement />,

    8: (
      <Documents
        title="Documentos"
        toggleStep={toggleStep}
        admin={true}
        temp={props.detalhes}
      />
    ),

    9: (
      <>
        <Status toggleStep={toggleStep} admin={true} enviando={enviando} />
      </>
    ),
  }[props.step] || (
      <>
        <h1>404!</h1>
        <h4>Esse formulário: {props.step}não existe! </h4>
      </>
    ));

  return (
    <>
      {step.cur >= step.maxStep ? (
        <RenderScreen step={step.cur} />
      ) : (
        <SimulatorLayout admin={true}>
          <RenderScreen step={step.cur} />
        </SimulatorLayout>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    cliente: state.dados.cliente,
    user: state.dados.user,
    detalhes: state.dados.detalhes,
    alterarProposta: state.dados.alterarProposta,
    buscarPropostas: state.dados.buscarPropostas,
  };
}

function mapActionToProps(dispatch) {
  return {
    setProposta(novaProposta) {
      dispatch(setProposta(novaProposta));
    },
    alterarUser(novoUser) {
      dispatch(setUser(novoUser));
    },
    setAlterarProposta(alterar) {
      dispatch(setAlterarProposta(alterar));
    },
    alterarCliente(novoCliente) {
      dispatch(setCliente(novoCliente));
    },
  };
}

export default connect(mapStateToProps, mapActionToProps)(Main);
